import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion';
import PropTypes from 'prop-types';
import * as React from 'react';
import { IoIosAdd, IoMdRemove } from 'react-icons/io';
import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';

function FAQs({ isGray = true, items }) {
  const [indices, setIndices] = React.useState([0]);

  function toggleItem(toggledIndex) {
    if (indices.includes(toggledIndex)) {
      setIndices(
        indices.filter((currentIndex) => currentIndex !== toggledIndex)
      );
    } else {
      setIndices([...indices, toggledIndex].sort());
    }
  }

  if (!items) {
    return <div></div>;
  }

  return (
    <InView threshold={0.6} triggerOnce={true}>
      {({ ref, inView }) => (
        <div ref={ref}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={inView ? { opacity: 1 } : { opacity: 0 }}
            transition={{ duration: 1 }}
            className={isGray ? 'bg-brand-gray' : 'bg-white'}
          >
            <div className="w-full xl:w-10/12 gap-8 mx-auto max-w-screen-2xl xl:grid-cols-5 pb-6">
              <div className="col-span-3 px-8 py-12 sm:px-6 lg:px-16">
                <h2 className="text-6xl py-6 font-semibold leading-none uppercase font-display text-center text-brand-orange">
                  Frequently Asked{' '}
                  <span className="text-brand-blue">Questions</span>
                </h2>

                <Accordion
                  className="mt-6"
                  index={indices}
                  onChange={toggleItem}
                >
                  {items.map((faq, index) => (
                    <AccordionItem
                      key={faq.id}
                      className={`py-2 border-b ${
                        indices.includes(index) ? 'text-brand-orange' : ''
                      }`}
                    >
                      <AccordionButton className="flex items-center w-full px-4 py-4 space-x-4 font-semibold text-left lg:px-8">
                        {indices.includes(index) ? (
                          <IoMdRemove className="flex-shrink-0 w-8" />
                        ) : (
                          <IoIosAdd className="flex-shrink-0 w-8" />
                        )}

                        <span className="text-sm md:text-lg">
                          {faq.question}
                        </span>
                      </AccordionButton>
                      <AccordionPanel className="py-2 pl-12 pr-4 text-lg lg:pl-20 lg:pr-8">
                        <div
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: faq.answer }}
                          className="prose-sm md:prose text-brand-blue"
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </InView>
  );
}

FAQs.propTypes = {
  isGray: PropTypes.bool,
  items: PropTypes.array.isRequired,
};
export { FAQs };
