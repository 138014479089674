/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from 'prop-types';
import * as React from 'react';

import FloatingBanner from './floating-banner';
import GtmTrackerHead from './gtm-tracker-head';
import ZohoTracker from './zoho-tracker';
import Footer from './footer';
import REAFooter from './reaFooter';
import SMFooter from './smFooter';
import JSONLD from './JSON-LD';
import SEOFooter from './seoFooter';
import Topbar from './topbar';

import { FAQs } from './faqs';
import {
  ballina,
  coffsHarbour,
  kempsey,
  portMacquarie,
  southWestRock,
} from './faqs/index';

const Layout = ({
  children,
  formName,
  hideForm = false,
  formTitle,
  isSuccess,
  isSEOPage = false,
  isREAPage = false,
  isSMPage = false,
  footerIsGray = false,
  pageTitle = '',
}) => {
  const isBrowser = typeof window !== 'undefined';

  async function conversionsAPI(eventID) {
    const cookies = document.cookie.split(';');
    let fbp = 'none';
    let fbc = 'none';

    cookies.map((cookie) => {
      if (cookie.includes('_fbp=')) {
        fbp = cookie.slice(cookie.indexOf('_fbp=') + 5);
        console.log(fbp);
      }
    });
    cookies.map((cookie) => {
      if (cookie.includes('_fbc=')) {
        fbc = cookie.slice(cookie.indexOf('_fbc=') + 5);
        console.log(fbc);
      }
    });

    if (fbc === 'none' && window.location.search.includes('fbclid=')) {
      const params = new URL(document.location).searchParams;
      fbc = `fb.1.${Date.now()}.${params.get('fbclid')}`;
    }
    try {
      const res = await fetch('/.netlify/functions/conversions-api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ eventType: 'ViewContent', fbp, fbc, eventID }),
      });
      const data = await res.json();
      console.log('Return from netlify functions conversionsAPI =', data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  React.useEffect(() => {
    const eventID = crypto.randomUUID();
    conversionsAPI(eventID, 'ViewContent');
    if (isBrowser && window.fbq)
      window.fbq('track', 'ViewContent', {}, { eventID });
  }, []);
  
  return (
    <>
      {/* <GtmTrackerHead /> */}
      <ZohoTracker />
      {/* <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-TGBZRVC"
          height="0"
          width="0"
          className="hidden"
        ></iframe>
      </noscript> */}
      <div className="flex flex-col min-h-screen font-sans antialiased text-gray-900">
        <JSONLD />
        <Topbar />
        <main id="main" className="flex-1 w-full mx-auto max-w-1440">
          {children}
          <FAQsSection page={pageTitle.toLowerCase()} />
        </main>
        {isSEOPage ? (
          <SEOFooter
            removeMargin={isSuccess}
            formTitle={formTitle}
            formName={formName}
            hideForm={hideForm}
            isGray
          />
        ) : isREAPage ? (
          <REAFooter
            removeMargin={isSuccess}
            formTitle={formTitle}
            formName={formName}
            hideForm={hideForm}
            isGray={true}
          />
        ) : isSMPage ? (
          <SMFooter
            removeMargin={isSuccess}
            formTitle={formTitle}
            formName={formName}
            hideForm={hideForm}
            isGray={true}
          />
        ) : (
          <Footer
            removeMargin={isSuccess}
            formTitle={formTitle}
            formName={formName}
            hideForm={hideForm}
            isGray={footerIsGray}
          />
        )}
        {/* <FloatingBanner /> */}
      </div>
    </>
  );
};

const FAQsSection = ({ page }) => {
  if (page.includes('ballina')) {
    return <FAQs items={ballina} />;
  }
  if (page.includes('coffs harbour')) {
    return <FAQs items={coffsHarbour} />;
  }
  if (page.includes('kempsey')) {
    return <FAQs items={kempsey} />;
  }
  if (page.includes('port macquarie')) {
    return <FAQs items={portMacquarie} />;
  }
  if (page.includes('south west rocks')) {
    return <FAQs items={southWestRock} />;
  }
  return <div></div>;
};

Layout.propTypes = {
  children: PropTypes.any,
  footerIsGray: PropTypes.bool,
  formName: PropTypes.any,
  formTitle: PropTypes.any,
  hideForm: PropTypes.any,
  isSuccess: PropTypes.any,
  isSEOPage: PropTypes.bool,
  pageTitle: PropTypes.string,
};

export { Layout };
