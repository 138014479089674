// @ts-nocheck
import PropTypes from 'prop-types';
import * as React from 'react';

const Input = React.forwardRef(function Input({
  labelVisible,
  label,
  id,
  name,
  required = false,
  type = 'text',
  placeholder,
  value,
  autoComplete,
  margin,
  handleChange,
  thinBorder = false,
  ...rest
}) {
  return (
    <label
      htmlFor={id}
      className={`w-full px-2${margin ? ' mt-3 sm:mt-0' : ''}`}
    >
      <span className={`${labelVisible ? 'text-sm font-semibold' : 'sr-only'}`}>
        {label}
      </span>
      <input
        id={id}
        name={name}
        required={required}
        type={type}
        placeholder={placeholder}
        value={value}
        autoComplete={autoComplete}
        onChange={handleChange}
        className={`${labelVisible ? 'mt-1 ' : ''}w-full h-9 px-3 py-1 ${
          thinBorder ? 'border' : 'border-2'
        } border-white rounded-none appearance-none bg-brand-blue focus:border-brand-orange focus:outline-none`}
        {...rest}
      />
    </label>
  );
});

Input.propTypes = {
  labelVisible: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  autoComplete: PropTypes.string,
  margin: PropTypes.bool,
  handleChange: PropTypes.func,
  thinBorder: PropTypes.bool,
};

export default Input;
