import { nanoid } from 'nanoid';

export const faqItems = [
  {
    id: nanoid(),
    question: 'What kind of packing material do you use?',
    answer:
      'We use strong, durable and sustainable packaging supplies like cardboard boxes, packing paper, bubble wrap, plastic bags and moving blankets to protect your stuff during the hustle and bustle of transition.',
  },
  {
    id: nanoid(),
    question: 'How long does it take to move interstate?',
    answer: `<div>
        <p>The moving time depends on many factors like:</p>
        <ul>
            <li><span>Moving day:</span> On weekends you may experience heavy traffic which can delay your move.</li>
            <li><span>Weather conditions:</span> It is possible that your shipment may get delayed because of adverse weather conditions like storms and rain along the way.</li>
            <li><span>Number of household or office items:</span>More the items, the longer it will take.</li>
            <li><span>Distance between pick up and final destination:</span> More the distance, the longer it will take. For instance, it takes longer to move from Ballina to Kempsey than to move from Ballina to Coffs Harbour.</li>
        </ul>
        <p>To know the estimated moving time, dial 1800 549 798</p>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'What size of shipping containers do you have?',
    answer: `<div>
        <p>We offer safe and versatile shipping containers of different sizes:</p>
        <ul>
            <li>10ft container for regular sized items like sports equipment or tools.</li>
            <li>20ft container for large scale items like vehicles.</li>
            <li>40ft container for building a farm shed or living space.</li>
        </ul>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'How much do you charge for storage services?',
    answer: `<div>
        <p>We offer rental storage services from one week to ten years starting at just $2 per day.</p>
        <p>Liaise with us on a pay-per-month package and we’ll custom your storage needs.</p>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'Which items do you not move?',
    answer: `<div>
        <p>Some of the items we generally avoid packing and moving are:</p>
        <ol>
            <li>Combustible items</li>
            <li>Hazardous chemicals</li>
            <li>Kerosene oil</li>
            <li>Paint</li>
            <li>Perishable items</li>
            <li>Car batteries</li>
            <li>Fertilizers</li>
            <li>Nail paint remover</li>
        </ol>
        <p>Dial 1800 549 798 & talk to our team members to know the complete list of prohibited items.</p>
    </div>`,
  },
];
