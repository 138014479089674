export default [
  {
    id: '0985b335-ba96-4ab7-9745-1334aa43135a',
    title: 'Removals',
    route: '#',
    submenu: [
      {
        id: '5accbb91-79ee-47b4-9a93-233502b1054a',
        title: 'South West Rocks',
        route: '/south-west-rocks-best-interstate-furniture-removalists/',
      },
      {
        id: '5accbb91-79ee-49b4-9a93-233502b1054a',
        title: 'Coffs Harbour',
        route: '/coffs-harbour-furniture-removals/',
      },
      {
        id: '5accbb91-49ee-49b4-9a93-233506j1054a',
        title: 'Newcastle',
        route: '/newcastle-furniture-removals/',
      },
      {
        id: '5accbb91-49ee-49b4-9a93-233502b1054a',
        title: 'Sydney',
        route: '/sydney-furniture-removals/',
      },
      {
        id: '5accbb91-49ee-49b4-9a93-233542b9054a',
        title: 'Sydney Interstate',
        route: '/sydney-interstate-removalist/',
      },
      {
        id: '5accbb91-49ee-99b6-9a93-233542b9054a',
        title: 'Northern Beaches',
        route: '/northern-beaches-furniture-removals/',
      },
      {
        id: '1accbb91-49ee-49b4-9a93-233502b1054a',
        title: 'Brisbane',
        route: '/brisbane-interstate-removals/',
      },
      {
        id: '1a0cbb91-49ee-49b4-9a93-233502b1054a',
        title: 'Byron Bay',
        route: '/byron-bay-furniture-removals/',
      },
      {
        id: '1accbb91-41ee-4944-9a53-233502b1054a',
        title: 'Taree',
        route: '/taree-furniture-removals/',
      },
      {
        id: '2ebb7fed-023c-4c45-a7c6-982a71d7654b',
        title: 'Port Macquarie',
        route: '/port-macquarie-furniture-removals/',
      },
      {
        id: '14ce1a41-2665-4396-85ca-4b2b0eaa41b2',
        title: 'Grafton',
        route: '/grafton-furniture-removals/',
      },
      {
        id: 'b7569b5c-9018-45ed-9b52-e7e74fd17c98',
        title: 'Ballina',
        route: '/ballina-furniture-removals/',
      },
      {
        id: 'f4a74b2c-4e08-1717-8d6a-78bc529319b4',
        title: 'Kempsey',
        route: '/kempsey-furniture-removals/',
      },
    ],
  },
  {
    id: '0985b335-ba96-4ab7-9745-13e4aa43135a',
    title: 'Moving',
    route: '/furniture-removal/',
    submenu: [
      {
        id: '5af3237f-37ce-45a0-8d30-eec274435254',
        title: 'Furniture Removal',
        route: '/furniture-removal/',
        mobileOnly: false,
      },
      // {
      //   id: '5af3037f-37ce-45a0-8d30-eec274435254',
      //   title: 'Inventory form',
      //   route: '/inventory-form/',
      // },
      {
        id: 'ec87d6be-37bf-46bf-a554-e82a19f72f91',
        title: 'Packing materials',
        route: '/packing-materials/',
      },
    ],
  },
  {
    id: 'effaf7ae-3dbe-43de-8a78-65c0e034a067',
    title: 'Storage',
    route: '/storage-solutions/',
  },
  {
    id: '5d8dc310-7da3-47ae-9b00-1170b24c9490',
    title: 'Containers',
    route: '/shipping-containers-for-sale/',
  },
  {
    id: '4b7116fc-2b69-46fc-97d4-a64d97fc4f51',
    title: 'About',
    route: '/about-us/',
    submenu: [
      {
        id: 'f3023b49-6c48-42f4-92a1-835f9a4f5681',
        title: 'About Us',
        route: '/about-us/',
        mobileOnly: false,
      },
      {
        id: 'f3027b49-6c48-42f4-92a1-835f9a4f5681',
        title: 'Team',
        route: '/meet-the-team/',
      },
      {
        id: 'e4d35373-f7b3-49b7-be22-0b41c232b9a5',
        title: 'Businesses We Work With',
        route: '/businesses-we-work-with/',
      },
      {
        id: 'ccf67ece-4884-4269-a645-1123087b7a',
        title: 'Blog',
        route: '/blog/',
      },
      {
        id: 'fcf67ece-4884-4269-a645-14e2a5087b7a',
        title: 'Privacy Policy',
        route: '/privacy-policy/',
      },
      {
        id: 'fcf67ece-4884-4269-a645-14e2a1232187b7a',
        title: 'COVID 19',
        route: '/covid-19/',
      },

      {
        id: '12asda-sa12-4269-a645-14e2a1232187b7a',
        title: 'Terms and Conditions',
        route: '/tncs/',
      },
    ],
  },
  {
    id: 'e61c537c-8473-4e66-b82c-9a9dd7b7b4db',
    title: 'Why',
    route: '/why-choose-us/',
  },
  {
    id: 'a0c3274b-3099-4c22-8e28-da7d7a7582c4',
    title: 'Contact',
    route: '/contact/',
  },
];
