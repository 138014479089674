import { Link } from 'gatsby';
import React, { useState } from 'react';
import { IoIosMenu } from 'react-icons/io';
import { animated, useSpring, useTransition } from 'react-spring';

import { MobileMenu } from './mobile-menu';

const Topbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navAnimations = useSpring({
    transform: isOpen ? 'translate3d(0%, 0, 0)' : 'translate3d(100%, 0, 0)',
  });

  const dismissButtonAnimations = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 0.5 },
    leave: { opacity: 0 },
  });

  const today = new Date();
  const currentMonth = today.toLocaleString('default', { month: 'long' });

  return (
    <header className="sticky top-0 z-30 w-full px-6 py-2 mx-auto font-semibold text-white bg-brand-blue max-w-1440">
      <div className="flex items-center justify-between max-w-5xl mx-auto lg:text-center lg:justify-center">
        <Link to="/furniture-removal/" className="flex items-center">
          <span className="inline-block text-sm lg:text-base">
            Our <span className="uppercase">{currentMonth}</span> bookings are
            filling up fast:&nbsp;
            <span className="hidden font-normal lg:inline-block">
              Get a quote in minutes
            </span>
            <span className="flex-shrink-0 inline-block tracking-wider underline rounded lg:px-2 lg:ml-2 lg:text-lg lg:bg-white lg:font-display lg:text-brand-blue lg:no-underline">
              <span className="lg:hidden">Get a </span>Free quote
            </span>
          </span>
        </Link>
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className={`${
            isOpen ? 'opacity-0' : 'opacity-100'
          } bg-brand-orange flex flex-shrink-0 items-center ml-4 px-2 py-1 rounded text-brand-blue text-2xl lg:hidden`}
        >
          <span className="font-sans text-xs font-semibold uppercase">
            Menu
          </span>
          <IoIosMenu className="ml-2" />
        </button>
        {dismissButtonAnimations.map(
          ({ item, key, props }) =>
            item && (
              <animated.button
                onClick={() => setIsOpen(false)}
                type="button"
                key={key}
                style={props}
                className="fixed inset-0 z-10 w-screen h-full bg-black lg:hidden"
              />
            )
        )}
        <MobileMenu
          navAnimations={navAnimations}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </div>
    </header>
  );
};

export default Topbar;
