import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

import navItems from '../../data/nav-items';
import { useOnClickOutside } from '../../hooks';
import logoText from '../../images/frontline-removals-and-storage.svg';
import logo from '../../images/logo.svg';

const SubMenu = ({ navItem }) => {
  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref = useRef();

  // State for our dropdown menus
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => setIsMenuOpen(false));

  return (
    <li ref={ref}>
      <button
        type="button"
        onClick={() => setIsMenuOpen((prev) => !prev)}
        className="inline-flex items-center justify-between p-2 font-semibold uppercase whitespace-nowrap transition duration-150 ease-in-out cursor-pointer hover:text-brand-orange"
      >
        {navItem.title}
        <IoIosArrowDown className="ml-1" />
      </button>
      {isMenuOpen && (
        <ul className="absolute z-10 grid px-2 py-1 -mx-2 text-white transition duration-150 ease-in-out rounded-md bg-brand-blue">
          {navItem.submenu.map((submenu) =>
            submenu.mobileOnly ? null : (
              <NavItem key={submenu.id} navItem={submenu} />
            )
          )}
        </ul>
      )}
    </li>
  );
};

SubMenu.propTypes = {
  navItem: PropTypes.shape({
    route: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    submenu: PropTypes.array,
  }),
};

const NavItem = ({ navItem }) => (
  <li className="inline-flex items-center justify-between p-2 whitespace-nowrap transition duration-150 ease-in-out cursor-pointer hover:text-brand-orange">
    <Link to={navItem.route}>{navItem.title}</Link>
  </li>
);

NavItem.propTypes = {
  navItem: PropTypes.shape({
    route: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    submenu: PropTypes.array,
  }),
};

const HeroNav = () => (
  <nav className="flex items-center justify-between w-full max-w-5xl mx-auto">
    <Link to="/" className="flex items-center flex-shrink-0">
      <img src={logo} alt="Frontline Removals logo." className="w-12 h-12" />
      <img src={logoText} alt="Frontline Removals logo." className="h-8 ml-2" />
    </Link>
    <ul className="hidden py-4 -mx-2 font-semibold uppercase text-brand-blue lg:flex">
      {navItems.map((navItem) =>
        navItem.submenu ? (
          <SubMenu key={navItem.id} navItem={navItem} />
        ) : (
          <NavItem key={navItem.id} navItem={navItem} />
        )
      )}
    </ul>
  </nav>
);

export { HeroNav };
