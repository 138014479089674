import { nanoid } from 'nanoid';

export const faqItems = [
  {
    id: nanoid(),
    question:
      'I’m moving my office. Can you help me move furniture and computers?',
    answer: `<div>
        <p>Definitely, yes. We have everything from packing material, crew to moving tools to pack, load and unload your office furniture—table, desk, chair, computers and other high-end equipment.</p>
        <p>Everything is planned and carefully executed which reduces the risk of any accidental damage to zero.  Consult <strong>Coffs Harbour’s</strong> best <strong>furniture removalists</strong> for consultation. </p>
        <p><a href="https://www.frontlineremovals.com.au/furniture-removal/" target="_blank">Request a free quote</a></p>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'What type of packing material do you use?',
    answer: `<div>
        <p>We use a range of premium-quality and sustainable packing supplies such as cartons, wrapping paper, bubble wrap, protectors, etc.</p>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'How much do you charge for a local move? ',
    answer: `<div>
        <p>The moving cost mainly depends on the following factors:</p>
        <ol>
            <li><strong>Distance:</strong> It is a no-brainer. The more the distance between the pick-up and delivery locations, the higher it will cost you.</li>
            <li><strong>Weight:</strong> The higher the weight of goods, the more it will cost you.</li>
            <li><strong>The number of items:</strong> From moving a small to a large home or office, the cost will multiply depending on the number of items. </li>
            <li><strong>The day of moving:</strong> The moving cost may be nominally high if it's a peak time like over the long weekend and public holidays.</li>
        </ol>
        <p>To get a rough estimate, dial 1800 549 798  or <a href="https://www.frontlineremovals.com.au/furniture-removal/" target="_blank">request a free quote</a></p>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'I’m moving abroad, can I store my items with you?',
    answer: `<div>
        <p>Yes. Whether you are moving local or abroad, we offer short and long term flexible storage solutions for your office or household items.</p>
        <p>All our storage facilities have CCTV cameras to ensure your items remain safe and secure while you are away. </p>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'Do you sell packing supplies?',
    answer: `<div>
        <p>Yes. We stock a range of packing supplies like packing paper, bubble wrap, cartons, and more at reasonable prices.</p>
        <p>You can buy these packing supplies standalone or we can adjust in the <a href="https://www.frontlineremovals.com.au/furniture-removal/" target="_blank">moving quote</a>.</p>
    </div>`,
  },
];
