import * as React from 'react';
import { Helmet } from 'react-helmet';

import json1 from '../data/jsonld-data-1.json';
import json2 from '../data/jsonld-data-2.json';

// https://technicalseo.com/tools/schema-markup-generator/
export default function JSONLD() {
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(json1, 2, null)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(json2, 2, null)}
      </script>
    </Helmet>
  );
}
