import { graphql, useStaticQuery } from 'gatsby';

export const useSanityGetAllPosts = () => {
  return useStaticQuery(graphql`
    {
      allSanityPost(sort: { fields: _createdAt, order: ASC }) {
        nodes {
          _createdAt(formatString: "MMMM DD, YYYY")
          categories {
            title
          }
          description
          id
          mainImage {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
          title
        }
      }
    }
  `);
};
