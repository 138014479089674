import PropTypes from 'prop-types';
import * as React from 'react';

import { HeroCTA } from './hero-cta';
import { HeroImage } from './hero-image';
import { HeroNav } from './hero-nav';


const Hero = ({
  image = <HeroImage />,
  nav = <HeroNav />,
  cta = <HeroCTA />,
}) => (
  <header className="relative">
    {image}
    <div className="absolute inset-0 flex justify-center px-6 py-4 hero-gradient">
      <div className="flex flex-col justify-between flex-1 pb-24">
        {nav}
        {cta}
      </div>
    </div>
  </header>
);

Hero.propTypes = {
  image: PropTypes.object,
  nav: PropTypes.object,
  cta: PropTypes.object,
};

export { Hero };
