import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

import amex from '../images/amex.svg';
import mastercard from '../images/mastercard.svg';
import visa from '../images/visa.svg';
import ContactForm from './forms/contact-form';
import { LogoFull } from './vectors';

function ValidateForm() {
  let firstName = document.forms.newformbottom.SingleLine2.value;
  let lastName = document.forms.newformbottom.SingleLine3.value;
  let email = document.forms.newformbottom.Email.value;
  let phoneNum = document.forms.newformbottom.PhoneNumber_countrycode.value;
  let success = true;

  document
    .getElementById('SingleLine2_error2')
    ?.classList.replace('block', 'hidden');

  document
    .getElementById('SingleLine3_error2')
    ?.classList.replace('block', 'hidden');

  document.getElementById('Email_error2')?.classList.replace('block', 'hidden');

  document
    .getElementById('PhoneNumber_error2')
    ?.classList.replace('block', 'hidden');

  if (firstName === null || firstName === '') {
    document
      .getElementById('SingleLine2_error2')
      ?.classList.replace('hidden', 'block');
    success = false;
  }
  if (lastName === null || lastName === '') {
    document
      .getElementById('SingleLine3_error2')
      ?.classList.replace('hidden', 'block');
    success = false;
  }
  if (email === null || email === '') {
    document
      .getElementById('Email_error2')
      ?.classList.replace('hidden', 'block');
    success = false;
  }
  if (phoneNum === null || phoneNum === '') {
    document
      .getElementById('PhoneNumber_error2')
      ?.classList.replace('hidden', 'block');
    success = false;
  }

  return success;
}

function NewForm() {
  return (
    <div className="max-w-4xl mx-auto mb-24">
      <form
        action="https://forms.zohopublic.com.au/frontlineremovals/form/REAInstantQuoteNEW/formperma/KWdRl8R_g-Su5FhWMylwsXwz2IlipogOsupXCsJ9s8g/htmlRecords/submit"
        name="newformbottom"
        method="POST"
        accept-charset="UTF-8"
        encType="multipart/form-data"
        id="newformbottom"
      >
        <input type="hidden" name="zf_referrer_name" />
        <input type="hidden" name="zf_redirect_url" />
        <input type="hidden" name="zc_gad" />
        <input type="hidden" name="utm_source" />
        <input type="hidden" name="utm_medium" />
        <input type="hidden" name="utm_campaign" />
        <input type="hidden" name="utm_term" />
        <input type="hidden" name="utm_content" />
        <input type="hidden" name="gclid" />
        <input type="hidden" name="referrername" />
        <div className="px-12 pt-20 pb-6 mt-12 bg-brand-blue">
          <div className="">
            <ul>
              <div className="w-full">
                <h1 className="text-5xl font-semibold leading-none text-white uppercase heading font-display">
                  Enter your details here for your{' '}
                  <span className="inline-block text-brand-orange">
                    quote
                  </span>
                </h1>
              </div>
              <li className="my-6">
                <label className="">
                  <div className="">
                    <span className="text-white rich-text">
                      Our customer service team works Monday to Friday 9am - 5pm
                      and will contact you as soon as possible.
                    </span>
                    <br />
                  </div>
                </label>
              </li>
              <li className="">
                <div className="">
                  <span>
                    <input
                      className="w-full px-3 py-3 mb-3"
                      type="text"
                      name="SingleLine2"
                      maxLength={255}
                      placeholder="First Name*"
                    />
                  </span>{' '}
                  <p id="SingleLine2_error2" className="hidden zf-errorMessage">
                    Please enter your first name
                  </p>
                </div>
              </li>
              <li className="">
                <div className="">
                  <span>
                    <input
                      className="w-full px-3 py-3 mb-3"
                      type="text"
                      name="SingleLine3"
                      checktype="c1"
                      maxlength="255"
                      fieldType={1}
                      placeholder="Last Name*"
                    />
                  </span>{' '}
                  <p id="SingleLine3_error2" className="hidden zf-errorMessage">
                    Please enter your last name
                  </p>
                </div>
              </li>
              <li className="">
                <div className="">
                  <span>
                    <input
                      className="w-full px-3 py-3 mb-3"
                      fieldType={9}
                      type="text"
                      maxlength="255"
                      name="Email"
                      checktype="c5"
                      placeholder="Email Address*"
                    />
                  </span>{' '}
                  <p id="Email_error2" className="hidden zf-errorMessage">
                    Please enter your email address
                  </p>
                </div>
              </li>
              <li className="">
                <div className=" zf-phonefld">
                  <div className="zf-phwrapper zf-phNumber">
                    <span>
                      <input
                        className="w-full px-3 py-3"
                        type="text"
                        compname="PhoneNumber"
                        name="PhoneNumber_countrycode"
                        maxlength="20"
                        checktype="c7"
                        phoneFormat="1"
                        isCountryCodeEnabled={false}
                        fieldType="11"
                        id="international_PhoneNumber_countrycode"
                        valType="number"
                        phoneFormatType="1"
                        placeholder="Contact Number*"
                      />
                    </span>
                  </div>
                  <p id="PhoneNumber_error2" className="hidden zf-errorMessageNoMB">
                    Please enter your phone number
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="mt-20 mb-4">
            <button
              onClick={(e) => {
                if (!ValidateForm()) e.preventDefault();
              }}
              className="flex items-center justify-center px-6 py-2 font-semibold text-white rounded bg-brand-orange border-brand-orange "
            >
              GET QUOTE NOW!
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

const REAFooter = ({ hideForm = false, formName, formTitle, removeMargin, isGray }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          facebook
          instagram
          phone
          phoneFormatted
          address
          googleMapsAddress
        }
      }
    }
  `);

  const {
    facebook,
    instagram,
    phone,
    phoneFormatted,
    address,
    googleMapsAddress,
  } = data.site.siteMetadata;

  const isBrowser = typeof window !== 'undefined';

  const doFB = () => {
    const eventID = crypto.randomUUID();
    console.log(eventID);
    conversionsAPI(eventID);
    if (isBrowser && window.fbq)
      window.fbq('track', 'Contact', {}, { eventID });
  };

  async function conversionsAPI(eventID) {
    const cookies = document.cookie.split(';');
    let fbp = 'none';
    let fbc = 'none';

    cookies.map((cookie) => {
      if (cookie.includes('_fbp=')) {
        fbp = cookie.slice(cookie.indexOf('_fbp=') + 5);
        console.log(fbp);
      }
    });
    cookies.map((cookie) => {
      if (cookie.includes('_fbc=')) {
        fbc = cookie.slice(cookie.indexOf('_fbc=') + 5);
        console.log(fbc);
      }
    });

    if (fbc === 'none' && window.location.search.includes('fbclid=')) {
      const params = new URL(document.location).searchParams;
      fbc = `fb.1.${Date.now()}.${params.get('fbclid')}`;
    }
    try {
      const res = await fetch('/.netlify/functions/conversions-api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ eventType: 'Contact', fbp, fbc, eventID }),
      });
      const data = await res.json();
      console.log('Return from netlify functions conversionsAPI =', data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && hideForm === false) {
      var f = document.createElement("iframe");   
f.src = 'https://forms.zohopublic.com.au/frontlineremovals/form/REAInstantQuote/formperma/LDTZGgbeNe5F99hc8C2eLFfMi-4IZ_-Ri0HN2yIiv4o?zf_rszfm=1';
f.style.border="none";                                           
f.style.height="655px";
f.style.width="100%";
f.style.zIndex="50";
f.style.transition="all 0.5s ease";// No I18N
var d = document.getElementById(formName);
d.appendChild(f);
      }
  
}, []);

  return (
    <footer
      className={`relative w-full mx-auto ${
        removeMargin ? 'mt-0' : 'mt-16'
      } max-w-1440`}
    >
      {!hideForm && (
        <div id={formName} className='relative max-w-4xl mx-auto -mt-12 -mb-6 lg:-mb-36'></div>
        // <NewForm/>
      )}
      <div
        className={`${hideForm ? '' : 'pt-24'} ${
          isGray ? 'bg-gray-200' : 'bg-brand-orange'
        } flex flex-wrap`}
      >
        <Link
          to="/"
          aria-label="Return to home page."
          className="relative w-full sm:w-1/3"
        >
          <span className="sr-only">
            Frontline Removals &amp; Storage logo.
          </span>
          <LogoFull
            isGray={isGray}
            className="w-64 px-6 py-4 mx-auto sm:h-full sm:w-full sm:absolute sm:inset-0"
          />
        </Link>
        <div
          className={`${
            isGray ? 'text-gray-800' : 'text-white'
          } flex flex-col justify-center w-full max-w-2xl px-6 py-4 overflow-hidden sm:w-2/3`}
        >
          <dl className="mt-3 sm:flex sm:mt-0 first:mt-0">
            <dt className="flex-shrink-0 font-semibold text-brand-blue">
              Head Office&nbsp;
            </dt>
            <dd className="flex-shrink-0">
              <a
                href={googleMapsAddress}
                target="_blank"
                rel="noopener noreferrer"
              >
                {address}
              </a>
            </dd>
          </dl>
          <dl className="mt-3 sm:flex sm:mt-0 first:mt-0">
            <dt className="flex-shrink-0 font-semibold text-brand-blue">
              Phone&nbsp;
            </dt>
            <dd className="flex-shrink-0">
              <a
                onClick={() => doFB()}
                href={`tel:1300656799`}
              >
                1300 656 799
              </a>
            </dd>
          </dl>
          <dl className="mt-3 sm:flex sm:mt-0 first:mt-0">
            <dt className="flex-shrink-0 font-semibold text-brand-blue">
              Opening Hours&nbsp;
            </dt>
            <dd className="flex-shrink-0">Monday – Friday 8:30am – 5pm</dd>
          </dl>
          <div className="flex items-center justify-between mt-6">
            <div className="flex items-center">
              <img
                src={visa}
                alt="Visa logo."
                className="w-12 ml-2 first:ml-0"
              />
              <img
                src={amex}
                alt="American Express logo."
                className="w-12 ml-2 first:ml-0"
              />
              <img
                src={mastercard}
                alt="Mastercard logo."
                className="w-12 ml-2 first:ml-0"
              />
            </div>
            <div className="flex items-center justify-center space-x-2">
              <a
                href={facebook}
                aria-label="View our Facebook page."
                className={`${
                  isGray
                    ? 'bg-brand-blue text-white'
                    : 'bg-white text-brand-orange'
                } flex items-center justify-center w-8 h-8 rounded-full shadow hover:shadow-md`}
              >
                <FaFacebookF />
              </a>
              <a
                href={instagram}
                aria-label="View our Facebook page."
                className={`${
                  isGray
                    ? 'bg-brand-blue text-white'
                    : 'bg-white text-brand-orange'
                } flex items-center justify-center w-8 h-8 rounded-full shadow hover:shadow-md`}
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>
      <a
        href="https://www.phirannodesigns.com.au/"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center justify-center px-6 py-1 text-center bg-white text-brand-blue"
      >
        Website by&nbsp;
        <span className="inline-block font-semibold">Phiranno Designs</span>
      </a>
      <div className='fixed bottom-0 left-0 w-full py-4 font-semibold text-center text-white sm:hidden bg-brand-orange'>                <a
                  onClick={() => doFB()}
                  href={`tel:${phone}`}
                  className="flex items-center justify-center"
                >
                  {/* <IoIosCall /> */}
                  <span className="text-sm">Call for BEST Price&nbsp;</span><span className='underline'>{phoneFormatted}</span>
                </a></div>
    </footer>
  );
};

REAFooter.propTypes = {
  formName: PropTypes.string,
  formTitle: PropTypes.string,
  hideForm: PropTypes.bool,
  isGray: PropTypes.bool,
  removeMargin: PropTypes.bool,
};

export default REAFooter;
