import { nanoid } from 'nanoid';

export const faqItems = [
  {
    id: nanoid(),
    question: 'How do you move heavy furniture items?',
    answer: `<div>
        <p>We are Kempsey’s expert furniture removalists. </p>
        <p>We have everything from high-quality packing supplies to trained staff, moving equipment and shipping containers.</p>
        <p>Some of the extra precautions we undertake to prevent any accidental damage are:</p>
        <ol>
            <li>We use packing supplies like moving blankets, bubble wrap, cardboard containers and furniture pads.</li>
            <li>We wrap furniture items with bubble wrap or plastic wrap to protect against unexpected rain, snow, etc.</li>
            <li>We cover the floor with cardboard boxes, blankets, etc.</li>
            <li>We have the right moving tools like dolly, slider, ropes to haul your furniture.</li>
            <li>Our shipping containers are well-insulated.</li>
        </ol>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'Do I need to disassemble my furniture?',
    answer: `<div>
        <p>It’s your choice. You can disassemble desks, beds, cabinets, dining tables and other furniture yourself.</p>
        <p>If you find it a difficult task, we will be more than happy to assist you with the disassembly of your furniture.</p>
        <p>Contact us at 1800 549 798.</p>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'Do you provide moving services even if the weather is bad?',
    answer: `<div>
        <p>Whether it’s raining or snowing outside, our experienced removalist team is always ready to help you.</p>
        <p>We have the right packing material and well-insulated shipping containers to make sure everything goes smoothly on the moving day.</p>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'How long does packing take?',
    answer: `<div>
        <p>The time of packing depends on mainly two factors:</p>
        <ul>
            <li>The size of your property</li>
            <li>Number of items</li>
        </ul>
        <p>Our professional team can help you plan and pack your stuff a couple of days before the moving day.</p>
        <p>To know the estimated packing time, get in touch with us at  1800 549 798.</p>
    </div>`,
  },
  {
    id: nanoid(),
    question:
      'Is there a minimum amount of time to keep items in a storage facility?',
    answer: `<div>
        <p>Yes, you can keep your precious possessions in a storage facility for a minimum of 1 week.</p>
    </div>`,
  },
];
