import { nanoid } from 'nanoid';

export const faqItems = [
  {
    id: nanoid(),
    question: 'What kind of shipping containers do you use?',
    answer: `<div>
        <p>We use solid steel shipping containers. They are safe, versatile and well-insulated to protect your belongings from rain, snow, pests and any unfortunate event.</p>
    </div>`,
  },
  {
    id: nanoid(),
    question:
      'Which equipment and tools do you use to haul furniture pieces?',
    answer: `<div>
        <p>We use various equipment like:</p>
        <ul>
            <li><strong>Furniture slider:</strong> Perfect to move anything from table to couch, bed and cabinet while protecting carpets from getting damaged.</li>
            <li><strong>Dolly:</strong> Offers an excellent way to move desks, dressers, bookshelf and other items.</li>
            <li><strong>Handtruck:</strong> Useful for lifting small furniture items without causing any strain to your back.</li>
            <li><strong>Ropes and straps:</strong> For securing furniture items on moving equipment and trucks.</li>
        </ul>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'What should I do with my expensive jewellery items?',
    answer: `<div>
        <p>We can transport your valuable possessions in the safest manner, but it’s best if you take them along with you.</p>
        <p>Carry jewellery and other high-value and sentimental items with you in your private vehicle.</p>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'How early should I book your moving services?',
    answer: `<div>
        <p>Sooner the better. We recommend booking moving services as early as possible, as our <strong>furniture removal</strong> dates in <strong>Port Macquarie</strong> sell out quickly.</p>
        <p>Moreover, you get plenty of time to work out the logistics and ensure the success of your relocation.</p>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'How do you pack upholstered furniture?',
    answer: `<div>
        <p>We use high-quality packing supplies like plastic wrap, moving blankets and shrink-wrap to pack your upholstered furniture items like sofa, couch and chair and prevent them from getting soiled or dirty.</p>
    </div>`,
  },
];
