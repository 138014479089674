import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

import amex from '../images/amex.svg';
import mastercard from '../images/mastercard.svg';
import visa from '../images/visa.svg';
import ContactForm from './forms/contact-form';
import { LogoFull } from './vectors';
import { IoIosCheckmark } from 'react-icons/io';

// @ts-ignore
const SEOFooter = ({ hideForm, formName, formTitle, isGray }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          facebook
          instagram
          phone
          phoneFormatted
          address
          googleMapsAddress
        }
      }
    }
  `);

  const {
    facebook,
    instagram,
    phone,
    phoneFormatted,
    address,
    googleMapsAddress,
  } = data.site.siteMetadata;

  const isBrowser = typeof window !== 'undefined';

  const doFB = () => {
    const eventID = crypto.randomUUID();
    console.log(eventID);
    conversionsAPI(eventID);
    if (isBrowser && window.fbq)
      window.fbq('track', 'Contact', {}, { eventID });
  };

  async function conversionsAPI(eventID) {
    const cookies = document.cookie.split(';');
    let fbp = 'none';
    let fbc = 'none';

    cookies.map((cookie) => {
      if (cookie.includes('_fbp=')) {
        fbp = cookie.slice(cookie.indexOf('_fbp=') + 5);
        console.log(fbp);
      }
    });
    cookies.map((cookie) => {
      if (cookie.includes('_fbc=')) {
        fbc = cookie.slice(cookie.indexOf('_fbc=') + 5);
        console.log(fbc);
      }
    });

    if (fbc === 'none' && window.location.search.includes('fbclid=')) {
      const params = new URL(document.location).searchParams;
      fbc = `fb.1.${Date.now()}.${params.get('fbclid')}`;
    }
    try {
      const res = await fetch('/.netlify/functions/conversions-api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ eventType: 'Contact', fbp, fbc, eventID }),
      });
      const data = await res.json();
      console.log('Return from netlify functions conversionsAPI =', data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && hideForm === false) {
      var f = document.createElement("iframe");   
f.src = 'https://forms.zohopublic.com.au/frontlineremovals/form/FrontlineRemovals/formperma/hkM77lTI53lWl457vreSUTKi8D0WYcvzzEB7zeK11BM?zf_rszfm=1';
f.style.border="none";                                           
f.style.height="600px";
f.style.width="100%";
f.style.zIndex="50";
f.style.transition="all 0.5s ease";// No I18N
var d = document.getElementById("bottom-form");
d.appendChild(f);
// window.addEventListener('message', function (){
// var zf_ifrm_data = typeof event.data === 'string' ? event.data.split("|") : null ;
// var zf_perma = zf_ifrm_data && zf_ifrm_data[0];
// // var zf_ifrm_ht_nw = zf_ifrm_data && z( parseInt(zf_ifrm_data[1], 10) + 15 ) + "px";
// // var iframe = document.getElementById("zf_div_akM77lTI53lWl457vreSUTKi8D0WYcvzzEB7zeK11BM").getElementsByTagName("iframe")[0];
// // if ( (iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0 ) {
// // var prevIframeHeight = iframe.style.height;
// // if ( prevIframeHeight != zf_ifrm_ht_nw ) {
// // iframe.style.height = zf_ifrm_ht_nw;
// // }   
// // }
// }, false);
      }
  
}, []);

  return (
    <footer className="relative w-full mx-auto mt-0 max-w-1440">
      {!hideForm && (
        // <ContactForm
        //   formTitle={formTitle}
        //   formName={formName}
        //   className="-mb-24"
        // />
        <div id="" className='relative max-w-4xl mx-auto mt-12 -mb-36'>
                  <div
          style={{
            marginLeft: '30px',
            marginRight: '30px',
            marginBottom: '-30px',
          }}
          className="pt-4 bg-brand-blue"
        >
          <div className="flex flex-wrap justify-center">
            <h3 className="flex items-center">
              <IoIosCheckmark className="flex-shrink-0 text-2xl text-white md:text-5xl" />
              <span className="text-sm font-semibold text-brand-orange md:text-lg">
                30+ Years Experience
              </span>
            </h3>
            <h3 className="flex items-center">
              <IoIosCheckmark className="flex-shrink-0 text-2xl text-white md:text-5xl" />
              <span className="text-sm font-semibold text-brand-orange md:text-lg">
                Caring & Reliable
              </span>
            </h3>
            <h3 className="flex items-center">
              <IoIosCheckmark className="flex-shrink-0 text-2xl text-white md:text-5xl" />
              <span className="text-sm font-semibold text-brand-orange md:text-lg">
                Excellent Value
              </span>
            </h3>
          </div>
          <h2 className="text-5xl font-semibold leading-none text-center text-white uppercase md:text-7xl font-display">
            Get a <span className="text-brand-orange">fast quote</span> now
          </h2>
          <p className="text-sm font-semibold text-center text-white md:text-xl">
            Complete the{' '}
            <span className="text-brand-orange">Quick Quote Form</span> Below to{' '}
            <span className="text-brand-orange">Save</span> on Your Move
          </p>
        </div>
        <div id="bottom-form"></div>
        </div>
      )}
      <div
        className={`${hideForm ? '' : 'pt-24'} bg-brand-gray flex flex-wrap`}
      >
        <Link
          to="/"
          aria-label="Return to home page."
          className="relative w-full sm:w-1/3"
        >
          <span className="sr-only">
            Frontline Removals &amp; Storage logo.
          </span>
          <LogoFull
            isGray={isGray}
            className="w-64 px-6 py-4 mx-auto sm:h-full sm:w-full sm:absolute sm:inset-0"
          />
        </Link>
        <div
          className={`${
            isGray ? 'text-gray-800' : 'text-white'
          } flex flex-col justify-center w-full max-w-2xl px-6 py-4 overflow-hidden sm:w-2/3`}
        >
          <dl className="mt-3 sm:flex sm:mt-0 first:mt-0">
            <dt className="flex-shrink-0 font-semibold text-brand-blue">
              Head Office&nbsp;
            </dt>
            <dd className="flex-shrink-0">
              <a
                href={googleMapsAddress}
                target="_blank"
                rel="noopener noreferrer"
              >
                {address}
              </a>
            </dd>
          </dl>
          <dl className="mt-3 sm:flex sm:mt-0 first:mt-0">
            <dt className="flex-shrink-0 font-semibold text-brand-blue">
              Phone&nbsp;
            </dt>
            <dd className="flex-shrink-0">
              <a
                onClick={() => doFB()}
                href={`tel:${phone}`}
              >
                {phoneFormatted}
              </a>
            </dd>
          </dl>
          <dl className="mt-3 sm:flex sm:mt-0 first:mt-0">
            <dt className="flex-shrink-0 font-semibold text-brand-blue">
              Opening Hours&nbsp;
            </dt>
            <dd className="flex-shrink-0">Monday – Friday 8:30am – 5pm</dd>
          </dl>
          <div className="flex items-center justify-between mt-6">
            <div className="flex items-center">
              <img
                src={visa}
                alt="Visa logo."
                className="w-12 ml-2 first:ml-0"
              />
              <img
                src={amex}
                alt="American Express logo."
                className="w-12 ml-2 first:ml-0"
              />
              <img
                src={mastercard}
                alt="Mastercard logo."
                className="w-12 ml-2 first:ml-0"
              />
            </div>
            <div className="flex items-center justify-center space-x-2">
              <a
                href={facebook}
                aria-label="View our Facebook page."
                className={`${
                  isGray
                    ? 'bg-brand-blue text-white'
                    : 'bg-white text-brand-orange'
                } flex items-center justify-center w-8 h-8 rounded-full shadow hover:shadow-md`}
              >
                <FaFacebookF />
              </a>
              <a
                href={instagram}
                aria-label="View our Facebook page."
                className={`${
                  isGray
                    ? 'bg-brand-blue text-white'
                    : 'bg-white text-brand-orange'
                } flex items-center justify-center w-8 h-8 rounded-full shadow hover:shadow-md`}
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>
      <a
        href="https://www.phirannodesigns.com.au/"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center justify-center px-6 py-1 text-center bg-white text-brand-blue"
      >
        Website by&nbsp;
        <span className="inline-block font-semibold">Phiranno Designs</span>
      </a>
      <div className='fixed bottom-0 left-0 w-full py-4 font-semibold text-center text-white sm:hidden bg-brand-orange'>                <a
                  onClick={() => doFB()}
                  href={`tel:${phone}`}
                  className="flex items-center justify-center"
                >
                  {/* <IoIosCall /> */}
                  <span className="text-sm">Call for BEST Price&nbsp;</span><span className='underline'>{phoneFormatted}</span>
                </a></div>
    </footer>
  );
};

SEOFooter.propTypes = {
  formName: PropTypes.string,
  formTitle: PropTypes.string,
  hideForm: PropTypes.bool,
  isGray: PropTypes.bool,
};

export default SEOFooter;
