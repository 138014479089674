import PropTypes from 'prop-types';
import * as React from 'react';

const FormRow = ({ children }) => (
  <div className="flex flex-wrap mt-6 -mx-2 sm:mt-3 sm:flex-nowrap">
    {children}
  </div>
);

FormRow.propTypes = {
  children: PropTypes.node,
};

export default FormRow;
