import { nanoid } from 'nanoid';

export const faqItems = [
  {
    id: nanoid(),
    question: 'How safe are your storage facilities?',
    answer: `<div>
        <p>We provide safe and secure storage facilities for any prized and valuable items for short or long periods.</p>
        <p>They are well-insulated and have CCTV surveillance to provide adequate protection against criminal activities, pests and natural disasters like fire and flood.</p>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'What type of furniture do you relocate?',
    answer: `<div>
        <p>We are highly-trained and have years of experience in relocating anything, from small to heavy and bulky furniture items hassle-free.</p>
        <p>It includes chairs, sofa, beds, couch, dressers, dining table, and other stuff.</p>
        <p>We have everything from high-quality storage boxes to moving equipment to make your relocation a breeze.</p>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'Can you pack the furniture for me? ',
    answer: `<div>
        <p>Yes, we are happy to assist you with any packing job.</p>
        <p>Whether it’s a large-scale office or a small house, we offer reliable and professional furniture packing services at competitive prices.</p>
    </div>`,
  },
  {
    id: nanoid(),
    question: `Do you need extra help with interstate furniture removals in South West Rocks?`,
    answer: `<div>
        <p>No. We are a large team of movers and packers that can help you with packing, loading, storing, unloading and unpacking office or household items. </p>
    </div>`,
  },
  {
    id: nanoid(),
    question: 'How long can I keep my items in the storage facility?',
    answer: `<div>
        <p>We offer long and short-term storage facilities. Your stuff is safe and secure with us, from 1 week to 10 years.</p>
    </div>`,
  },
];
